import { Alert } from "@mui/material";

export default function AlertContainer() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: "30px",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <div>
        <Alert severity="error">
          <ul>
            <li>
              Vignette practices for speaking and non-speaking parts are
              Thursday, (June 13th) from 6:30pm to 8pm, and Saturday, (June
              15th) from 9am to 12pm, both at the Monroe building. Please be
              there!
            </li>
            <li style={{ marginTop: 10 }}>
              All youth come meet your MQ families Saturday, June 22nd, from
              10am to 11am at the Monroe building.
            </li>
            <li style={{ marginTop: 10 }}>
              Please see the updated{" "}
              <a href="/packing-list.pdf" target={"_blank"}>
                packing list
              </a>{" "}
              that includes now that you bring your own sack lunch on the first
              day (Wednesday).
            </li>
          </ul>
        </Alert>
      </div>
    </div>
  );
}
