export default function Header({ title }) {
  return (
    <div className="header-container">
      <div className="image-container">
        <img
          src="./moroni_in_the_wilderness.png"
          alt="Header Background"
          className="header-image"
        />
        <div className="overlay"></div>
      </div>
      <div className="overlay-text">
        <h1
          className="header-text"
          style={{ fontFamily: "GFS Didot serif", fontWeight: 800 }}
        >
          {title || "Moroni's Quest"}
        </h1>
      </div>
    </div>
  );
}
