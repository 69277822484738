import NavBar from "../NavBar";
import Header from "../Header";
import Cards from "./Cards";
import InviteSection from "./InviteSection";
import AlertContainer from "./AlertContainer";

export default function Home() {
  return (
    <div>
      <NavBar page="home" />
      <Header />
      <AlertContainer />
      <InviteSection />
      <Cards />
    </div>
  );
}
