export default function NavCard({ title, onClick }) {
  return (
    <div className="nav-card" onClick={onClick}>
      <h4
        style={{
          color: "white",
          fontFamily: "GFS Didot",
          fontWeight: 600,
          fontSize: 24,
          margin: 0,
        }}
      >
        {title}
      </h4>
    </div>
  );
}
