import Header from "../Header";
import NavBar from "../NavBar";
import Accordion from "react-bootstrap/Accordion";

export default function Faqs() {
  return (
    <>
      <NavBar page="faqs" />
      <Header title={"FAQs"} />

      <div style={{ padding: "30px 10%" }}>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>How much does it cost?</Accordion.Header>
            <Accordion.Body>
              The event is free for all youth and participating adults.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              When and where is the Moroni’s Quest event?
            </Accordion.Header>
            <Accordion.Body>
              Moroni’s Quest will be held Wednesday through Saturday, 26-29 June
              2024, at Cascade Park in Arlington.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              How will youth be organized during the event?
            </Accordion.Header>
            <Accordion.Body>
              Each youth will be assigned to a pair of “goodly parents” who will
              be your guides through the event. We expect to have about eight
              youth per “family.”
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              What are we going to do during Moroni’s Quest?
            </Accordion.Header>
            <Accordion.Body>
              During the four days of this event, you will experience stories
              from the Book of Mormon brought to life by youth and adults in the
              stake. Those stories will be accompanied by activities, such as
              Nephite and Lamanite battles and other fun activities, personal
              reflection, and family discussion with goodly parents. There will
              also be free time to allow you to have fun with various sports and
              activities available at Cascade Park.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              What do I need to do to prepare?
            </Accordion.Header>
            <Accordion.Body>
              We encourage all participants to finish reading the Book of Mormon
              before Moroni’s Quest begins{" "}
              <a
                href={"/moronis-quest-book-of-mormon-reading-schedule.pdf"}
                target={"_blank"}
                rel="noreferrer"
              >
                (Reading chart)
              </a>
              . Additionally, you’ll need to create your Book of Mormon costume{" "}
              <a href={"/costumes"}>(Costumes)</a>. Packing lists will be
              provided to registered attendees about a month before the event.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>
              Can I be involved in one of the Book of Mormon stories that are
              brought to life?
            </Accordion.Header>
            <Accordion.Body>
              Yes! This is one of coolest parts of participating, having your
              moment to be a Book of Mormon character! When you complete the
              registration form, please check the box that indicates you are
              interested in being a cast member. Casting leaders will be in
              touch with interested youth during the coming weeks to make
              assignments.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>
              What is involved in being a cast member?
            </Accordion.Header>
            <Accordion.Body>
              No memorization of lines is required because the audio for each
              scene will be pre-recorded and used at the event. You will be
              invited to record the audio for your part, if you would like to do
              that (not required). There will also be at least one in-person
              rehearsal for each story to work out the acting and movements that
              you’ll need to attend. You may also need to enhance your costume a
              bit to reflect your characters; stake costume specialists are
              available to help. Details will be worked out with casting and
              directing leaders.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}
