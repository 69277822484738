export default function Box({ text }) {
  return (
    <div
      style={{
        border: "2px solid #4B312C",
        padding: "10px 25px",
        margin: "20px",
        height: "120px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderRadius: "10px",
      }}
    >
      <p
        className="font-blue"
        style={{ textAlign: "center", marginBottom: 0, fontSize: "18px" }}
      >
        {text}
      </p>
    </div>
  );
}
