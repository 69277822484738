import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../Header";
import NavBar from "../NavBar";
import Box from "./Box";

export default function Costumes() {
  return (
    <>
      <NavBar page="costumes" />
      <Header title="Costumes" />

      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/OLRpNBhavKE?si=2Pra_-4R2Fxg5JYD?autoplay=1"
          frameborder="0"
          allow="autoplay;fullscreen"
        ></iframe>
      </div>

      <h4
        className="cursive font-blue"
        style={{
          textAlign: "center",
          fontWeight: 600,
          marginTop: 90,
          marginBottom: 50,
        }}
      >
        For youth costumes, you can use:
      </h4>
      <Container style={{ paddingBottom: "50px" }}>
        <Row>
          <Col sm={12} md={6}>
            <Box text={"A sheet with a circle cutout for your head"} />
          </Col>
          <Col sm={12} md={6}>
            <Box
              text={"An oversized plain-colored t-shirt can serve as a tunic"}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Box text={"Scarves for belts; you can add ornamentation"} />
          </Col>
          <Col sm={12} md={6}>
            <Box
              text={
                "Use earth tone colors; watch the Book of Mormon videos for ideas"
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <Box
              text={
                "A T-shirt and shorts or pants underneath your costume for modesty"
              }
            />
          </Col>
          <Col sm={12} md={6}>
            <Box text={"Be creative and have fun with it!"} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
