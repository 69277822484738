import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/navigation/Home";
import Costumes from "./components/navigation/Costumes";
import "bootstrap/dist/css/bootstrap.min.css";
import Faqs from "./components/navigation/Faqs";

const router = createBrowserRouter([
  {
    path: "/",
    element: Home(),
  },
  {
    path: "/costumes",
    element: Costumes(),
  },
  {
    path: "/faqs",
    element: Faqs(),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
