import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";

export default function NavBar({ page }) {
  const navigate = useNavigate();

  return (
    <Navbar expand="lg" style={{ backgroundColor: "#faf9f0 !important" }}>
      <Container>
        <Navbar.Brand>
          {" "}
          <img
            src="./moronis-quest-logo.png"
            alt="logo"
            style={{ width: "50px" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              active={page === "home"}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              active={page === "costumes"}
              onClick={() => {
                navigate("/costumes");
              }}
            >
              Costumes
            </Nav.Link>
            <Nav.Link
              active={page === "faqs"}
              onClick={() => {
                navigate("/faqs");
              }}
            >
              FAQs
            </Nav.Link>
            <Nav.Link
              href="/moronis-quest-book-of-mormon-reading-schedule.pdf"
              target={"_blank"}
            >
              Reading Schedule
            </Nav.Link>
            <Nav.Link href="/packing-list.pdf" target={"_blank"}>
              Packing List
            </Nav.Link>
            <Nav.Link
              href="https://assets.churchofjesuschrist.org/ec/6a/ec6a32b2ded011eda8abeeeeac1eefc32321140e/pd60004035_000_interactive.pdf"
              target={"_blank"}
            >
              Permission/Medical Form
            </Nav.Link>
            <Nav.Link
              href="https://docs.google.com/forms/d/e/1FAIpQLSdYuxOPUwUAxPnNc6fVc8jB5_q7dwo4n819O3H2RKEZQpLJEA/viewform?usp=sf_link"
              target={"_blank"}
            >
              Register
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
