import NavCard from "../Card";
import { useNavigate } from "react-router-dom";

export default function Cards() {
  const navigate = useNavigate();

  return (
    <div
      className="container card-container"
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        paddingTop: "30px",
        marginBottom: "50px",
      }}
    >
      <NavCard
        description={""}
        title={"Register"}
        onClick={() => {
          window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSdYuxOPUwUAxPnNc6fVc8jB5_q7dwo4n819O3H2RKEZQpLJEA/viewform?usp=sf_link",
            "_blank"
          );
        }}
      />
      <NavCard
        description={""}
        title={"Costumes"}
        onClick={() => {
          navigate("/costumes");
        }}
      />
      <NavCard
        description={""}
        title={"FAQs"}
        onClick={() => {
          navigate("/faqs");
        }}
      />
    </div>
  );
}
