import Divider from "./Divider";

export default function InviteSection() {
  return (
    <>
      <Divider marginTop={60} />
      <Divider marginTop={10} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="invite-text"
          style={{
            margin: 0,
            width: "70%",
            textAlign: "center",
            color: "#4B312C",
            fontFamily: "GFS Didot",
          }}
        >
          <h3 className="">
            <b>Snohomish Washington Youth Conference</b>
          </h3>
          <h3
            className="invite-body"
            style={{
              margin: 0,
              textAlign: "center",
              color: "#4B312C",
              fontFamily: "GFS Didot",
            }}
          >
            {"For youth turning 14 this year & up,"} <br />
            {"you are invited to join us for Moroni’s Quest!"} <br />
            {"It will be an amazing, immersive Book of Mormon experience!"}{" "}
            <br />
            <h3
              className="cursive bold invite-text-date"
              style={{ marginTop: 15 }}
            >
              June 26th-29th @ Cascade Park
            </h3>
          </h3>
        </div>
      </div>
      <Divider marginTop={0} />
      <Divider marginTop={10} />
    </>
  );
}
